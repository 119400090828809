function boom() {
  let paraprahs = document.querySelectorAll(".bowe-reveal-text .wp-block-heading");

  paraprahs.forEach((paraprahs) => {
    let htmlString = "";
    let pArray = paraprahs.textContent.split("");
    for (let i = 0; i < pArray.length; i++) {
      htmlString += `<span>${pArray[i]}</span>`;
    }

    paraprahs.innerHTML = htmlString;
  });

  let spans = document.querySelectorAll(".bowe-reveal-text span");

  function revealSpans() {
    for (let i = 0; i < spans.length; i++) {
      if (spans[i].parentElement.getBoundingClientRect().top < window.innerHeight / 2) {
        let { left, top } = spans[i].getBoundingClientRect();
        top = top - window.innerHeight * 0.3;

        let opacityValue = 1 - (top * 0.01 + left * 0.001) < 0.1 ? 0.1 : 1 - (top * 0.01 + left * 0.001).toFixed(3);
        opacityValue = opacityValue > 1 ? 1 : opacityValue.toFixed(3);
        spans[i].style.opacity = opacityValue;
      }
    }
  }

  window.addEventListener("scroll", revealSpans);
}

document.addEventListener("DOMContentLoaded", boom);
